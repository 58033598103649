body {
  font-family: "Helvetica Neue", Arial, sans-serif;
  background-color: #f5f1e3;
}

.MuiAppBar-root {
  background-color: #ffffff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.MuiButton-containedPrimary {
  background-color: #1b9aaa;
  color: #ffffff;
}

.audio-human::-webkit-media-controls-play-button,
.audio-human::-webkit-media-controls-panel {
  background-color: #f0f0f0 !important;
  color: black;
  padding-left: 0px !important;
  border-radius: 0px !important;
}

/* Styles for bot messages */
.audio-bot::-webkit-media-controls-play-button,
.audio-bot::-webkit-media-controls-panel {
  background-color: #dcf8c6 !important;
  color: black;
  padding-left: 0px !important;
  border-radius: 0px !important;
}

.MuiButton-containedPrimary:hover {
  background-color: #146d7c;
}

.MuiContainer-root {
  background-color: #ffffff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

.bubble-highlight {
  background-color: #ffffcc !important;
  transition: background-color 2s ease;
}
.bubble-normal {
  transition: background-color 2s ease;
}
