.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    background-color: #f5f1e3; /* Old lace */
    padding: 20px;
  }
  
  .login-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 400px;
    background-color: #ffffff; /* White */
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .login-input {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border-radius: 5px;
    border: 1px solid #dddbcb; /* Bone */
    font-size: 16px;
    box-sizing: border-box;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .login-button {
    background-color: #1b9aaa; /* Blue (Munsell) */
    border-color: #1b9aaa;
    color: #ffffff; /* White */
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    margin-top: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .login-button:hover {
    background-color: #146d7c; /* Darker shade of Blue (Munsell) */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .login-button:disabled {
    background-color: #cccccc; /* Grey */
    cursor: not-allowed;
  }
  
  .login-message-container {
    width: 100%;
    height: 0;
    overflow: hidden;
    transition: height 0.5s ease;
  }
  
  .login-message {
    margin-top: 20px;
    font-size: 14px;
    text-align: center;
    font-weight: bold;
    opacity: 0;
    transition: opacity 0.5s ease;
  }
  
  .login-message.error {
    color: #d9534f; /* Red for error */
  }
  
  .login-message.success {
    color: #5cb85c; /* Green for success */
  }
  
  .login-message.visible {
    opacity: 1;
  }
  
  .login-heading {
    font-family: 'Helvetica Neue', Arial, sans-serif;
    font-size: 36px;
    margin-bottom: 20px;
    color: #050505; /* Black */
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .login-loading {
    border: 4px solid #f3f3f3; /* Light grey */
    border-top: 4px solid #1b9aaa; /* Blue (Munsell) */
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
    margin: 0 auto;
    
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  @media (max-width: 600px) {
    .login-form {
      padding: 20px;
    }
  
    .login-heading {
      font-size: 28px;
    }
  
    .login-input {
      font-size: 14px;
      padding: 8px;
    }
  
    .login-button {
      font-size: 14px;
      padding: 8px 16px;
    }
  }
  